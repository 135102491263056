import React from "react";
import { Link } from "react-router-dom";
import CustomFont from "../../components/CustomFont";
// import './customFont.scss';

const SubscriptionConfirmed = ({}) => {

  return (
    <div className="container__text">
      <CustomFont
        tag="h1"
        className="small__font tt--upps ta--center c--green pb-0"
        content="subscription confirmed."
      />
      <Link
        to="/"
        className="container__button"
      >
        <CustomFont
          tag="p"
          className="small__font tt--upps ta--center pb-0 c--grey c--black input--button width--fit"
          content="back to home"
        />
      </Link>
    </div>
  );
};

export default SubscriptionConfirmed;