import React from "react";
import CustomFont from "../CustomFont";
import "./mailchimpInput.scss";

const MailchimpInput = ({}) => {
  return (
    <div
      id="mc_embed_signup"
      className="wrapper__form"
    >
      <form
        action="https://anothergallery.us14.list-manage.com/subscribe/post?u=332bbe384634ad055b8f4bbc1&amp;id=5c9c4a1a1b&amp;f_id=00effbe0f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate container__form"
        target="_self"
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          <CustomFont
            tag="h2"
            className="small__font tt--upps ta--center pb-0"
            content="stay in touch"
          />
          <div className="mc-field-group container__input pb-1">
            <input
              type="email"
              defaultValue=""
              name="EMAIL"
              className="required email input--text small__font tt--upps"
              id="mce-EMAIL"
              placeholder="email adress"
              autoComplete="off"
              required
            />
            <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
          </div>
          <div hidden={true}>
            <input type="hidden" name="tags" value="7720796" />
          </div>
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_332bbe384634ad055b8f4bbc1_5c9c4a1a1b"
              tabIndex="-1"
              defaultValue=""
            />
          </div>
          <div className="clear container__button">
            <input
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button input--button small__font tt--upps c--green"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default MailchimpInput;
