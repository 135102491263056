import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DataContext from "./config/dataContext";
import Header from "./components/Header";
// import Footer from "./components/Footer";
import Home from "./pages/Home";
import SubscriptionConfirmed from "./pages/SubscriptionConfirmed";
import "./styles/reset.scss";
import "./index.scss"

const App = () => {
  const [maxWidth, setMaxWidth] = useState(window.innerWidth);
  const [maxHeight, setMaxHeight] = useState(window.innerHeight);


  window.addEventListener('resize', function(){
    setMaxHeight(window.innerHeight)
    setMaxWidth(window.innerWidth)
  });

  return (
    <DataContext.Provider
      value={{}}
    >
      <Router>
        <div className="wrapper__application">
          <div
            className="container__application"
            style={{
              height: maxHeight + "px"
            }}
          >
            <Header />
            <main className="wrapper wrapper__middle">
              <div className="container__middle">
                <Switch>
                  <Route path="/" exact render={() => <Home />} />
                  <Route path="/subscription-confirmed" exact render={() => <SubscriptionConfirmed />} />
                  <Route path="*" render={() => <Home />} />
                </Switch>
              </div>
            </main>
            {/* <Footer /> */}
          </div>
        </div>
      </Router>
    </DataContext.Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
