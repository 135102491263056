import React from "react";
import CustomFont from "../CustomFont";
// import './customFont.scss';

const Header = ({}) => {

  return (
    <header className="wrapper wrapper__top">
      <div className="container__top">
        <CustomFont
          tag="p"
          className="small__font ta--center tt--upps"
          content="Website under construction"
        />
      </div>
    </header>
  );
};

export default Header;
