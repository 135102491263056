import React from "react";
import MailchimpInput from "../MailchimpInput";
// import './customFont.scss';

const Footer = ({}) => {

  return (
    <footer className="wrapper wrapper_bottom">
      <div className="container__bottom">
        <MailchimpInput />
      </div>
    </footer>
  );
};

export default Footer;