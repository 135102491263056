import React from "react";
// import Logo from "../../assets/logo";
import Footer from "../../components/Footer";
// import './customFont.scss';

const Home = ({}) => {

  return (
    <div className="container__logo">
      {/* <Logo /> */}
      <Footer />
    </div>
  );
};

export default Home;